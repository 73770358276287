<template>
  <div class="fmcon">
    <div>
      <template v-if="language=='zh'">
        <myimage :url='url'></myimage>
      </template>
      <template v-if="language=='TW'">
        <myimage :url='urlTW'></myimage>
      </template>
      <template v-if="language=='En'">
        <myimage :url='urlEn'></myimage>
      </template>
    </div>
    <div class="hidden-xs-only">
      <div :class="screenWidth>=992?'fmmain-service':'fmmain-service-sm ' ">
      <div class="topimage">
<!--        <img src="../../assets/image/famous/famous1.png" alt="">-->
<!--        <img src="../../assets/image/famous/famous2.png" alt="">-->
<!--        <img src="../../assets/image/famous/famous3.png" alt="">-->
<!--        <img src="../../assets/image/famous/famous4.png" alt="">-->
        <template v-if="language=='zh'">
          <img src="../../assets/image/famous/famouszh.png">
        </template>
        <template v-if="language=='TW'">
          <img src="../../assets/image/famous/famouszhTW.png">
        </template>
        <template v-if="language=='En'">
          <img src="../../assets/image/famous/famousEn.png" style="height: 286PX">
        </template>
      </div>
      <div class="main-service-bot">
        <div class="english-yellow">{{$t('Famousschool.Studyabroadprogram')}}</div>
        <div class="service-con">
          <div class="servicetop">{{$t('Famousschool.名校直通车计划优势')}}</div>
          <div class="servicebot"></div>
        </div>
        <div class="famous-con">
          <div class="famous-left">
            {{$t('Famousschool.HK汇生会的名校直通车计划')}}
          </div>
          <div class="famous-right">
            <!-- <div class="famous-right-left"></div>
            <div class="famous-right-right"></div> -->
            <img :src="topurl" alt="">
          </div>
        </div>
        <div class="famous-twocon">
          <div>
            <div class="famous-twotil">{{$t('Famousschool.计划合适人群本科')}}</div>
            <div class="famous-twobot">
              <div>{{$t('Famousschool.不想读预科')}}</div>
              <div>{{$t('Famousschool.预科在读难以通过')}}</div>
              <div>{{$t('Famousschool.语言成绩不达标')}}</div>
            </div>
          </div>
          <div>
            <div class="famous-twotil">{{$t('Famousschool.计划合适人群研究生')}}</div>
            <div class="famous-twobot">
              <div> {{$t('Famousschool.本科院校背景较差')}}</div>
              <div>{{$t('Famousschool.GPA偏低')}}</div>
              <div>{{$t('Famousschool.语言成绩不达标')}}</div>
              <div>{{$t('Famousschool.软能力不足')}}</div>
              <div>{{$t('Famousschool.国内考研失利后想入读名校')}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-service-bottwo">
        <div class="english-yellow">{{$t('Famousschool.Studyabroadprogram')}}</div>
        <div class="service-con">
          <div class="servicetop">{{$t('Famousschool.直通车计划服务流程')}}</div>
          <div class="servicebot"></div>
          <div class="service-plan">
            <div  :class="language=='En'?'serviceplanheight':'serviceplanheightzh'">
              <div class="service-plantitle">{{$t('Famousschool.详细的留学规划')}}</div>
              <div class="planline"></div>
              <div class="service-plancon">
                {{$t('Famousschool.基于企业内部庞大的数据库资料')}}
              </div>
            </div>
            <div :class="language=='En'?'serviceplanheight':'serviceplanheightzh'">
              <div class="service-plantitle">{{$t('Famousschool.全方位的背景提升')}}</div>
              <div class="planline"></div>
              <div class="service-plancon">
                {{$t('Famousschool.根据选校定位为学生匹配相关的背景提升项目和机会')}}
              </div>
            </div>
            <div :class="language=='En'?'serviceplanheight':'serviceplanheightzh'">
              <div class="service-plantitle">{{$t('Famousschool.优质的文书材料')}}</div>
              <div class="planline"></div>
              <div class="service-plancon">
                {{$t('Famousschool.文书顾问与学生进行头脑风暴')}}
              </div>
            </div>
            <div :class="language=='En'?'serviceplanheight':'serviceplanheightzh'">
              <div class="service-plantitle">{{$t('Famousschool.高效的笔试面试特训')}}</div>
              <div class="planline"></div>
              <div class="service-plancon">
                {{$t('Famousschool.HK汇生会拥有丰富的本科')}}
              </div>
            </div>
            <div :class="language=='En'?'serviceplanheight':'serviceplanheightzh'">
              <div class="service-plantitle">{{$t('Famousschool.升学后的留学援助')}}</div>
              <div class="planline"></div>
              <div class="service-plancon">
                {{$t('Famousschool.HK汇生会提供的学生公寓完美解决了学生在港留学的住宿问题')}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="backgroundf7">
      <div :class="screenWidth>=992?'fmmain-service ':'fmmain-service-sm ' ">
        <div class="backgroundf7-bot ">
          <div class="english-yellow">{{$t('Famousschool.ADVANTAGE')}}</div>
          <div class="service-con">
            <div class="servicetop">{{$t('Famousschool.选择智优')}}</div>
            <div class="servicebot"></div>
            <div class=" famous-bot-hk">
              <div>
                <div>
                  <img src="../../assets/niu.png" alt="">
                </div>
                <div class="famous-bot-hkright">
                  <div class="famous-bot-hktitle">{{$t('Famousschool.香港本土高端留学机')}}</div>
                  <div>
                    <div>{{$t('Famousschool.年高端申请经验')}}</div>
                    <div>{{$t('Famousschool.优质的定制化文书材料')}}</div>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <img src="../../assets/image/famous/h2.png" alt="">
                </div>
                <div class="famous-bot-hkright">
                  <div class="famous-bot-hktitle">{{$t('Famousschool.一站式服务流程')}}</div>
                  <div>
                    <div>{{$t('Famousschool.专业资深导师团队')}}</div>
                    <div>{{$t('Famousschool.包括香港本地教授助阵')}}</div>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <img src="../../assets/image/famous/h3.png" alt="">
                </div>
                <div class="famous-bot-hkright">
                  <div class="famous-bot-hktitle">{{$t('Famousschool.海量成功案例')}}</div>
                  <div>
                    <div>{{$t('Famousschool.录取成功率')}}</div>
                    <div>{{$t('Famousschool.录取率')}}</div>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <img src="../../assets/image/famous/h4.png" alt="">
                </div>
                <div class="famous-bot-hkright">
                  <div class="famous-bot-hktitle">{{$t('Famousschool.一站式留学服务')}}</div>
                  <div>
                   {{$t('Famousschool.提供完善的接机')}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 錄取捷報 -->
    <div :class="screenWidth>=992?'fmmain-service ':'fmmain-service-sm ' ">
<!--      <div class="main-service-botthree">-->
<!--        <div class="english-yellow">{{$t('Famousschool.SUCCESSOFFER')}}</div>-->
<!--        <div class="service-con">-->
<!--          <div class="servicetop"> {{$t('Famousschool.录取捷报')}}</div>-->
<!--          <div class="servicebot"></div>-->
<!--          <div class="success-offer">-->
<!--            <div class="" v-for="(item,index) in StudyAbroadList" :key='index'>-->
<!--              <div class="success-offerleft">-->
<!--                 <img :src="baseUrl+item.image" alt="">-->
<!--              </div>-->
<!--              <div class="success-offerright" style="text-align:left">-->
<!--                <div> {{$t('Famousschool.学生姓名')}}{{item.studentName}}</div>-->
<!--                <div> {{$t('Famousschool.申请类型')}}{{item.applyType}}</div>-->
<!--                <div> {{$t('Famousschool.学术成绩')}}{{item.academicRecord}}</div>-->
<!--                <div> {{$t('Famousschool.英语成绩')}}{{item.englishResults}}</div>-->
<!--                <div> {{$t('Famousschool.录取大学')}}{{item.admissionSchool}}</div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="morebtn" @click="moreclick"> {{$t('Famousschool.查看更多')}}</div>-->
<!--        </div>-->
<!--      </div>-->
      <div class="content2">
        <div class="title" style="margin: 50PX 0 80PX 0">
          <div class="english-yellow">{{$t('overseasStudy.SUCCESSOFFER')}}</div>
          <div class="service-con">
            <div class="servicetop">
              {{$t('overseasStudy.录取捷报')}}
            </div>
            <div class="servicebot"></div>
          </div>
        </div>
        <div class="successoffer">
          <div v-for="(item,index) in StudyAbroadList" :key='index'>
            <div class="successoffer-left">
              <img :src="baseUrl+item.image" alt="">
            </div>
            <div class="successoffer-right">
              <div> {{$t('overseasStudy.学生姓名')}}：{{item.studentName}}</div>
              <div> {{$t('overseasStudy.申请类型')}}：{{item.applyType}}</div>
              <div> {{$t('overseasStudy.学术成绩')}}：{{item.academicRecord}}</div>
              <div> {{$t('overseasStudy.英语成绩')}}：{{item.englishResults}}</div>
              <div> {{$t('overseasStudy.录取大学')}}：{{item.admissionSchool}}</div>
            </div>
          </div>
        </div>
        <div class="btn"  @click="moreclick">{{$t('overseasStudy.查看更多')}}</div>
      </div>
    </div>
    </div>
      <!-- 移动端 -->
    <div class="hidden-sm-and-up">
      <div class="content-sm">
      <div>
        <template v-if="language=='zh'">
          <img src="../../assets/phone/famous/zs.png" alt="" style="width:335PX;height:358PX;margin-top:34PX">
        </template>
        <template v-if="language=='TW'">
          <img src="../../assets/phone/famous/zsTW.png" alt="" style="width:335PX;height:358PX;margin-top:34PX">
        </template>
        <template v-if="language=='En'">
          <img src="../../assets/phone/famous/zsEn.png" alt="" style="width:335PX;height:358PX;margin-top:34PX">
        </template>
      </div>
        <div class="title">
          <div class="english-yellow"> {{$t('Famousschool.OverseasStudy')}}</div>
          <div class="service-con">
            <div class="servicetop">{{$t('Famousschool.一站式留学')}}</div>
            <div class="line2"></div>
          </div>
        </div>
        <div class="yizhanshi">
            <div class="img" style="margin:0 auto">
              <el-image
                  :src="topurl"
                  style="height: 197PX; width: 335PX"
                  fit="cover"
              ></el-image>
              <!-- <div class="beijing"></div> -->
            </div>
            <div class="text">
               {{$t('Famousschool.HK汇生会的名校直通车计划')}}
            </div>
            <div class="famous-twocon-sm">
              <div :class="language=='En'?'famousheight':'famousheight2'">
                <div class="famous-twotil-sm"> {{$t('Famousschool.计划合适人群本科')}}</div>
                <div class="famous-twobot-sm">
                  <div> {{$t('Famousschool.不想读预科')}}</div>
                  <div> {{$t('Famousschool.预科在读难以通过')}}</div>
                  <div> {{$t('Famousschool.语言成绩不达标')}}</div>
                </div>
              </div>
              <div  :class="language=='En'?'famousheight':'famousheight2'">
                <div class="famous-twotil-sm"> {{$t('Famousschool.计划合适人群研究生')}}</div>
                <div class="famous-twobot-sm">
                  <div>  {{$t('Famousschool.本科院校背景较差')}}</div>
                  <div> {{$t('Famousschool.GPA偏低')}}</div>
                  <div> {{$t('Famousschool.语言成绩不达标')}}</div>
                  <div> {{$t('Famousschool.软能力不足')}}</div>
                  <div> {{$t('Famousschool.国内考研失利后想入读名校')}} </div>
                </div>
              </div>
            </div>
        </div>
        <div class="title" style="margin-bottom: 40PX">
          <div class="english-yellow">{{$t('Famousschool.SERVICESYSTEM')}}</div>
          <div class="service-con">
            <div class="servicetop">{{$t('Famousschool.科学智能的服务体系')}}</div>
            <div class="line2"></div>
          </div>
        </div>
        <div class="service-plan">
            <div :class="language=='En'?'service-plan-height2':'service-plan-height'">
              <div class="service-plantitle"> {{$t('Famousschool.详细的留学规划')}}</div>
              <div class="planline"></div>
              <div class="service-plancon">
                 {{$t('Famousschool.基于企业内部庞大的数据库资料')}}
              </div>
            </div>        
            <div :class="language=='En'?'service-plan-height2':'service-plan-height'">
            <div class="service-plantitle"> {{$t('Famousschool.全方位的背景提升')}}</div>
              <div class="planline"></div>
              <div class="service-plancon">
                 {{$t('Famousschool.根据选校定位为学生匹配相关的背景提升项目和机会')}}
              </div>
            </div>              
            <div :class="language=='En'?'service-plan-height2':'service-plan-height'">
              <div class="service-plantitle"> {{$t('Famousschool.优质的文书材料')}}</div>
              <div class="planline"></div>
              <div class="service-plancon">
                 {{$t('Famousschool.文书顾问与学生进行头脑风暴')}}
              </div>
            </div>            
            <div :class="language=='En'?'service-plan-height2':'service-plan-height'">
              <div class="service-plantitle"> {{$t('Famousschool.高效的笔试面试特训')}}</div>
              <div class="planline"></div>
              <div class="service-plancon">
                    {{$t('Famousschool.HK汇生会拥有丰富的本科')}} 
              </div>
            </div>            
            <div :class="language=='En'?'service-plan-height2':'service-plan-height'">
              <div class="service-plantitle"> {{$t('Famousschool.升学后的留学援助')}} </div>
              <div class="planline"></div>
              <div class="service-plancon">
                 {{$t('Famousschool.HK汇生会提供的学生公寓完美解决了学生在港留学的住宿问题')}}
              </div>
          </div>      
        </div>
        <div class="title">
          <div class="english-yellow">{{$t('Famousschool.ADVANTAGE')}}</div>
          <div class="service-con">
            <div class="servicetop"> {{$t('Famousschool.选择智优')}}</div>
            <div class="line2"></div>
          </div>
        </div>
        <div class="famous-hk">
           <template v-if="language=='zh'">
             <img src="@/assets/phone/overseasStudy/zy.png" alt="">
           </template>
          <template v-if="language=='TW'">
            <img src="@/assets/phone/overseasStudy/zyTW.png" alt="">
          </template>
          <template v-if="language=='En'">
            <img src="@/assets/phone/overseasStudy/zyEn.png" alt="">
          </template>
        </div>
        <div class="title" style="height:50PX">
          <div class="english-yellow">{{$t('Famousschool.SUCCESSOFFER')}}</div>
          <div class="service-con">
            <div class="servicetop">{{$t('Famousschool.录取捷报')}}</div>
            <div class="line2"></div>
          </div>
        </div>
        <div class="success-offer-sm">
          <div class="" v-for="(item, index) in StudyAbroadList" :key="index">
            <div class="success-offerleft-sm">
               <img :src="baseUrl+item.image" alt="">
            </div>
            <div class="success-offerright-sm">
              <div>{{$t('Famousschool.学生姓名')}}{{item.studentName}}</div>
              <div>{{$t('Famousschool.申请类型')}}{{item.applyType}}</div>
              <div>{{$t('Famousschool.学术成绩')}}{{item.academicRecord}}</div>
              <div>{{$t('Famousschool.英语成绩')}}{{item.englishResults}}</div>
              <div>{{$t('Famousschool.录取大学')}}{{item.admissionSchool}}</div>
            </div>
          </div>
        </div>
        <div class="btn"  @click="moreclick"> {{$t('Famousschool.查看更多')}}</div>
    </div>
    </div>
    <Mydialog ref="icon" @submit="submitchange"></Mydialog>
    <Myicon @iconchange='iconchange'></Myicon>
  </div>
</template>

<script>
import myimage from '@/components/myimage'
import Mydialog from "@/components/dialog"
import Myicon from '@/components/mainicon'
import {hongKongStudyAbroadList,addMessageRecord} from "@/api/index.js"
export default {
  components: {
    myimage,
    Mydialog,
    Myicon
  },
  data() {
    return {
      screenWidth: document.body.clientWidth,
      screenHeight: document.body.clientHeight,
      src: "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
      url: require('@/assets/image/mxztc.png'),
      urlTW: require('@/assets/image/mxztcTW.png'),
      urlEn: require('@/assets/image/mxztcEn.png'),
      StudyAbroadList:[],//录取捷报-副学士
      baseUrl:'',
      topurl:require('@/assets/image/mxztctop.png'),
      language:'zh'
    }
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.documentElement.clientWidth; //实时宽度
        window.screenHeight = document.documentElement.clientHeight; //实时高度
        console.log(this.screenWidth, this.screenHeight);
        that.screenWidth = window.screenWidth;
        that.screenHeight = window.screenHeight;
      })();
    };
  },
  created(){
    this.baseUrl=this.$store.state.baseUrl
    this.language=localStorage.getItem('language')
    this._hongKongStudyAbroadList(4,8)
  },
  methods: {
    //在线咨询（名校直通车）
    submitchange(e){
        e.category=9
      addMessageRecord(e).then(()=>{
        this.$message.success(this.$t('admission.已提交'))
      })
    },
    //录取捷报
    _hongKongStudyAbroadList(category,type){
      hongKongStudyAbroadList({
        category:category,
        type:type
      }).then(res=>{
        this.StudyAbroadList=res.data
        console.log('StudyAbroadList',this.StudyAbroadList)
      })
    },
    iconchange(){
      this.$refs.icon.dialogVisible=true
    },
    moreclick() {
      this.$router.push({
        path: '/Admission',
        query: {
          name: "Admission",
          twoindex: 0
        }
      });
    }
  }
}
</script>

<style lang="scss">
.fmcon {
  width: 100vw;
}

.fmmain-service {
  width: 1176PX;
  margin: 0 auto;
  padding-bottom: 50PX;
}

.fmmain-service-sm {
  width: 1176PX;
  margin: 0 auto;
  padding-bottom: 50PX;
}

.fmmain-service > div, .fmmain-service-sm > div {
  position: relative;
  top: 0;
}

.topimage {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 70PX 0;
}

.topimage img {
  width: 1176PX;
  height: 286PX;

}

.main-service-bot {
  height: 800PX;
  margin: 70PX 0;
}

.main-service-bottwo {
  height: 567PX;
  /* margin: 70PX 0; */
}

.main-service-botthree {
  height: 850PX;
  margin: 70PX 0;
}
.content2 {
  width: 1176px;
  margin: 0 auto;

  .btn {
    width: 234px;
    height: 70px;
    border-radius: 35px;
    line-height: 70px;
    text-align: center;
    background-color: #fe992b;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    bottom: 5px;
    margin: 0 auto 50PX auto;
  }

  .successoffer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    > div {
      width: 574px;
      height: 360px;
      display: flex;
      flex-direction: row;
      box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
      margin-bottom: 28px;
      padding: 37px 30px;

      .successoffer-left {
        width: 216px;
        height: 286px;
        background: #F7F7F7;
        margin-right: 24px;
      }

      .successoffer-left img {
        width: 216px;
        height: 286px;
      }

      .successoffer-right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: left;
        font-size: 24px;
        font-weight: 400;
        color: #333333;
      }
    }

  }
}
.english-yellow {
  color: #FE992B;
  font-size: 44px;
  opacity: 0.3;
}

.service-con {
  width: 100%;
  position: absolute;
  top: 15px;
}

.servicetop {

  font-size: 32px;
  color: #333;
  font-weight: bold;
  padding: 10PX 0;
}

.servicebot {
  width: 150PX;
  height: 6PX;
  background: #FE992B;
  border-radius: 3px;
  margin: 0 auto;
}

.famous-con {
  //margin-top: 50PX;
  /* height: 300px; */
  width: 100%;
  display: flex;
  flex-direction: row;
  //align-items: center;
  justify-content: space-between;
  padding-top: 100PX ;
}

.famous-left {
  width: 690PX;
  height: 134PX;
  font-size: 18PX;
  color: #333;
  text-align: left;
  line-height: 36PX;
  margin-right: 46PX;
}

.famous-right {
  width: 440PX;
  height: 296PX;
  display: flex;
  flex-direction: row;
  position: relative;
  top: 0;
}
.famous-right img{
  width: 440PX;
  height: 296PX;
}
.famous-right > div {
  width: 15vw;
  height: 8vw;
}

.famous-right-left {
  //background: #333;
  position: absolute;
  right: 40PX;
  z-index: 1;
  top: -100PX;
}

.famous-right-right {
  background: #FEF8E9;
  position: absolute;
  right: 0;
  top: -60PX;
}

.famous-twocon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 70PX auto;
}

.famous-twocon > div {
  width: 574PX;
  height: 228PX;
  background: #FEF8E9;
  border-radius: 12PX;
  border: 1px solid #F78A13
}

.famous-twocon > div:hover, .service-plan > div:hover {
  background: linear-gradient(134deg, #FCA648 0%, #FE992B 100%);
  cursor: pointer;
  color: #FFF !important;
  transition: all 0.5s;
}

.famous-twotil {
  font-size: 20PX;
  font-weight: bold;
  margin: 20PX 0 27PX 0;
}

.famous-twobot {
  font-size: 16PX;
  padding: 0 40PX;
  text-align: left;
}
.service-plan-height{
  height: 250PX!important;
}
.service-plan-height2{
  height: 350PX!important;
}
.service-plan {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 50PX;
}
.serviceplanheight{
  height:450PX!important;
}
.serviceplanheightzh{
  height:354PX!important;
}
.service-plan > div {
  width:220PX;
  padding: 0 30PX;
  //height: 354PX;
  background: #FEF8E9;
  border-radius: 12PX;
  position: relative;
}

.service-plantitle {
  margin: 43px auto 33px auto;
  font-size: 20PX;
  font-weight: bold;
  position: relative;
  z-index: 1;
}

.service-plancon {
  text-align: center;
  font-size: 16PX;
}

.planline {
  width: 50%;
  height: 10PX;
  background: linear-gradient(180deg, #FE992B 0%, rgba(254, 153, 43, 0.3) 100%);
  border-radius: 5PX;
  position: absolute;
  top: 60px;
  text-align: center;
  left: calc(50% - 25%);
}

.backgroundf7 {
  width: 100vw;
  padding-top: 20PX;
  background: #f7f7f7;
}

.backgroundf7-bot {

  height: 540PX;
  margin-top: 70PX;
}

.famous-bot-hk {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 50PX 0 24PX 0;
}

.famous-bot-hk > div {
  width:542PX;
  height: 164PX;
  border-radius: 12PX;
  background: #FFF;
  margin-bottom: 24PX;
  display: flex;
  flex-direction: row;
  font-size: 16PX;
  text-align: left;
}

.famous-bot-hk > div img {
  width: 124PX;
  height: 124PX;
  margin: 20PX 24PX 0 20PX;
}

.famous-bot-hkright {
  padding: 32PX 0;
}

.famous-bot-hktitle {
  font-size: 24PX;
  font-weight: bold;
  margin-bottom: 17PX;

}

.success-offer {
  margin-top: 75PX;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.success-offer > div {
  width: 30%;
  height: 340PX;
  display: flex;
  flex-direction: row;
  padding-bottom: 80PX;
}

.success-offerleft {
  //background: #333;
  width: 45%;
  height: 100%;
  margin-right: 15PX;
}

.success-offerright {
  width: 55%;
  line-height: 26PX;
  font-size: 16PX;
  white-space: pre-line;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #333;
}

.success-offerright span {
  color: #FE992B;
}

.morebtn {
  width: 234PX;
  height: 70PX;
  line-height: 70PX;
  background: #FE992B;
  border-radius: 35PX;
  color: #FFFFFF;
  font-size: 28PX;
  margin: auto;
  margin-bottom: 80PX;
}
/* 移动端 */

  .content-sm {
    width: 335PX;
    margin: 0 auto;
     .title {
      position: relative;
      height: 80PX;
      margin-top: 20PX;

      .english-yellow {
        color: #fe992b;
        font-size: 20PX;
        opacity: 0.3;
      }

      .service-con {
        width: 100%;
        position: absolute;
        top: 0;
      }

      .servicetop {
        font-size: 20PX;
        color: #333;
        font-weight: bold;
        padding: 10PX 0;
        margin: 0 auto;
      }
      .line2{
        width: 69PX;
        height: 3PX;
        background: #fe992b;
        border-radius: 3PX;
        margin: 0 auto;
      }
    }
     .servicetop2 {
        width: 160px;
        font-size: 32PX;
        color: #333;
        font-weight: bold;
        padding: 10PX 0;
        border-radius: 3PX;
        margin: 0 auto;
      }
     .service-plan {
        width: 335PX;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 16PX;
      }
.planline {
  width: 50%;
  height: 10PX;
  background: linear-gradient(180deg, #FE992B 0%, rgba(254, 153, 43, 0.3) 100%);
  border-radius: 5PX;
  position: absolute;
  top: 40PX;
  text-align: center;
  left: calc(50% - 25%);
}
.service-plan > div {
  width: 162PX;
  padding: 0 19PX;
  //height: 250PX;
  background: #FEF8E9;
  border-radius: 12PX;
  position: relative;
  margin-bottom: 10PX;
}

.service-plantitle {
  margin: 24PX auto 24PX auto;
  font-size: 16PX;
  font-weight: bold;
  position: relative;
  z-index: 1;
}

.service-plancon {
  text-align: left;
  font-size: 12PX;
}
.service-plan > div:hover{
  background: linear-gradient(134deg, #FCA648 0%, #FE992B 100%);
  cursor: pointer;
  color: #FFF !important;
  transition: all 0.5s;
}
.famous-hk img{
  width: 335PX;
  height: 456PX;
  margin-top: 16PX ;
}

      .success-offer-sm {
        margin-top: 25PX;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
      }

      .success-offer-sm > div {
        padding: 20PX 16PX 20PX 16PX;
        background: #fff;
        width: 335PX;
        height: 247PX;
        display: flex;
        flex-direction: row;
        align-items: center;
        // padding-bottom: 80px;
        margin-bottom: 16PX;
        box-shadow: 0px 3PX 6PX 1PX rgba(0, 0, 0, 0.16);
      }

      .success-offerleft-sm {
        //background: #333;
        width: 138PX;
        height: 207PX;
        margin-right: 15PX;
      }
      .success-offerleft-sm img {
        width: 138PX;
        height: 207PX;
      }
      .success-offerright-sm {
        width: 50%;
        line-height: 26PX;
        height: 207PX;
        text-align: left;
        font-size: 14PX;
        white-space: pre-line;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: #333;
      }
      .btn {
            width: 150PX;
            height: 48PX;
            background-color: #FE992B;
            border-radius: 24PX;
            color: #fff;
            font-size: 16PX;
            line-height: 48PX;
            text-align: center;
            margin: 10PX auto 20PX auto;
          }
     .yizhanshi {
      display: flex;
      margin-bottom: 120px;
      text-align: left;
      flex-direction: column;
      // height: 430PX;

      .text {
        text-align: left!important;
        width: 335PX;
        line-height: 24PX;
        font-size: 14PX;
        margin-top: 50PX;
      }

      .img {
        position: relative;
        .beijing {
          width: 305PX;
          height: 197PX;
          background-color: #fef8e9;
          position: absolute;
          bottom: -29PX;
          right: 0;
          z-index: -1;
        }
      }
      .famous-twocon-sm {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
      }
      .famousheight{
        height: 160PX;
      }
       .famousheight2{
         height: 144PX;
       }
      .famous-twocon-sm > div {
        width: 335PX;
        background: #FEF8E9;
        border-radius: 12PX;
        border: 1px solid #F78A13;
        margin-top: 16PX;
      }

      .famous-twocon-sm > div:hover {
        background: linear-gradient(134deg, #FCA648 0%, #FE992B 100%);
        cursor: pointer;
        color: #FFF !important;
        transition: all 0.5s;
      }
      .famous-twotil-sm {
        font-size: 12PX;
        font-weight: bold;
        margin: 16PX 0 8PX 0;
        padding-left: 23PX;
      }
      .famous-twobot-sm {
        font-size: 10PX;
        padding: 0 23PX;
        line-height: 18PX;
        text-align: left;
      }
    }
  }
</style>